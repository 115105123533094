import { Button, Card, Grid } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useNavigate } from "react-router-dom";

interface CountCardProps {
  title: string;
  amount: number;
  hrefTitle: string;
  href: string;
}

export const CountCard: React.FC<CountCardProps> = ({ title, amount, href, hrefTitle }): React.ReactElement => {
  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();

  const navigate = useNavigate();

  return (
    <Card
      title={title}
      extra={<Button onClick={() => navigate(href)}>{hrefTitle}</Button>}
      style={{ width: !screen.xl ? "100%" : "33%" }}
      bodyStyle={{ padding: "1rem" }}
    >
      <div style={{ textAlign: "center" }}>
        <Title level={5} style={{ margin: 0 }}>
          {amount}
        </Title>
      </div>
    </Card>
  );
};
