import { AxiosResponse } from "axios";
import { AuthResponse } from "../../shared/interfaces/AuthResponse.interface";
import authResourceService from "./rest-resources/Auth-Resource.service";

class AuthService {
  public async login(email: string, password: string): Promise<AuthResponse> {
    try {
      const axiosResponse: AxiosResponse<any> = await authResourceService.login(email, password);
      return axiosResponse.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async authenticate(): Promise<AuthResponse> {
    try {
      const axiosResponse: AxiosResponse<any> = await authResourceService.authenticate();
      return axiosResponse.data;
    } catch (error: unknown) {
      throw error;
    }
  }

  public async logout(): Promise<void> {
    try {
      return await authResourceService.logout();
    } catch (error: unknown) {
      throw error;
    }
  }
}

export const authService = new AuthService();
