import { UpdateGuestDto } from "../../../shared/dtos/UpdateGuest.dto";
import { UpdateSeatingPlan } from "../../../shared/dtos/UpdateSeatingPlan.dto";
import { WebApi } from "../WebApi";

export class GuestResourceService extends WebApi {
  public async getAll() {
    return this.request({
      method: "GET",
      url: "/guest",
    });
  }

  public async toggleAttendance(id: number) {
    return this.request({
      method: "PATCH",
      url: `/guest/${id}/attendance`,
    });
  }

  public async updateSeatingPlan(seatingPlan: Array<UpdateSeatingPlan>) {
    return this.request({
      method: "POST",
      url: "/guest/seatingplan",
      data: seatingPlan,
    });
  }

  public async update(updateGuestDto: UpdateGuestDto) {
    return this.request({
      method: "PATCH",
      url: `/guest/${updateGuestDto.id}`,
      data: updateGuestDto,
    });
  }

  public async delete(id: number) {
    return this.request({
      method: "DELETE",
      url: `/guest/${id}`,
    });
  }
}

const guestResourceService = new GuestResourceService();
export default guestResourceService;
