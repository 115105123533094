import { Breakpoint, Button, notification, Table } from "antd";
import React from "react";
import { useData } from "../../context/Data";
import { utils } from "../../services/api/utils/Utils";
import { PaymentsTable } from "./PaymentsTable";
import { DeleteOutlined } from "@ant-design/icons";
import { Supplier } from "../../shared/interfaces/Supplier.interface";
import { supplierService } from "../../services/api/Supplier.service";

export const SuppliersTable = (): React.ReactElement => {
  const { suppliers, setSuppliers } = useData();
  const [api, contextHolder] = notification.useNotification();

  const onClickDeleteSupplier = async (supplier: Supplier): Promise<void> => {
    const suppliersCopy = [...suppliers];
    try {
      await supplierService.delete(supplier.id);
      const supplierIndex = suppliers.findIndex((s) => s.id === supplier.id);
      suppliersCopy.splice(supplierIndex, 1);
      setSuppliers(suppliersCopy);
      api.success({
        message: `${supplier?.name} erfolgreich entfernt.`,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    }
  };

  const dataSource = suppliers.map((supplier) => ({
    key: supplier.id,
    id: supplier.id,
    name: supplier.name,
    job: supplier.job,
    totalCostsInEuro: supplier.totalCostsInEuro,
    totalCostsInEuroString: utils.convertNumberToEuroString(supplier.totalCostsInEuro),
    payments: supplier.payments,
    remainingAmountInEuro: utils.convertNumberToEuroString(
      supplier.payments.reduce(
        (accumulator, currentValue) => accumulator - currentValue.amountInEuro,
        supplier.totalCostsInEuro
      )
    ),
    actions: <Button icon={<DeleteOutlined />} onClick={() => onClickDeleteSupplier(supplier)} />,
  }));

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      responsive: ["md"] as Array<Breakpoint>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Job",
      dataIndex: "job",
      key: "job",
      responsive: ["md"] as Array<Breakpoint>,
    },
    {
      title: "Gesamtkosten in Euro",
      dataIndex: "totalCostsInEuroString",
      key: "totalCostsInEuroString",
    },
    {
      title: "Restbetrag",
      dataIndex: "remainingAmountInEuro",
      key: "remainingAmountInEuro",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  return (
    <>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 500 }}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <PaymentsTable selectedSupplier={record} />,
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      />
    </>
  );
};
