import { Card, Col, Grid } from "antd";
import React from "react";
import { Draggable, DraggingStyle, Droppable, NotDraggingStyle } from "react-beautiful-dnd";
import { TableDroppable } from "../../container/SeatingPlan";
import { Guest } from "../../shared/interfaces/Guest.interface";

interface GuestTablesDroppablesProps {
  tables: Array<TableDroppable>;
}

export const GuestTablesDroppables: React.FC<GuestTablesDroppablesProps> = ({ tables }): React.ReactElement => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  const getGuestItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none" as any,
    padding: 6,
    margin: ".2rem",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",
    border: "1px solid gray",
    borderRadius: "6px",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const renderGuestTableDroppables = (): Array<JSX.Element> => {
    const dropableTables: Array<JSX.Element> = [];

    for (const table of tables) {
      if (table.name !== "Brautpaar" && table.name !== "NO TABLE") {
        const element = (
          <Col span={breakpoints.md ? 6 : 24} key={table.id}>
            <Droppable
              droppableId={table.id.toString()}
              direction="horizontal"
              isDropDisabled={table.guests.length === table.availableSeats}
            >
              {(provided, snapshot) => (
                <Card
                  ref={provided.innerRef}
                  style={{ margin: ".2rem" }}
                  title={`${table.name} - ${table.guests.length} / ${table.availableSeats}`}
                  bodyStyle={{ display: "flex", flexWrap: "wrap" }}
                >
                  {table.guests.map((guest: Guest) => (
                    <Draggable key={guest.id.toString()} draggableId={guest.id.toString()} index={guest.id}>
                      {(provided, snapshot) => (
                        <div key={guest.id}>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getGuestItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            {guest.firstName} {guest.lastName}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Card>
              )}
            </Droppable>
          </Col>
        );
        dropableTables.push(element);
      }
    }

    return dropableTables;
  };

  return <>{renderGuestTableDroppables()}</>;
};
