import { Button, Grid } from "antd";
import { useNavigate } from "react-router-dom";
import { ContainerHeader } from "../components/ContainerHeader";
import { SuppliersTable } from "../components/tables/SuppliersTable";
import { useData } from "../context/Data";
import { utils } from "../services/api/utils/Utils";

export const Suppliers = () => {
  const { suppliers } = useData();
  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();
  const payments = suppliers.map((supplier) => supplier.payments).flat();
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <ContainerHeader title="Dienstleister" />
      </div>
      <div
        style={{
          marginBlock: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: screen.sm ? "flex-start" : "flex-end",
        }}
      >
        <div>
          {` Gesamtkosten: ${utils.convertNumberToEuroString(
            suppliers.reduce((accumulator, currentValue) => accumulator + currentValue.totalCostsInEuro, 0)
          )}`}
        </div>
        <div>
          {`Bereits gezahlt: ${utils.convertNumberToEuroString(
            payments.reduce((accumulator, currentValue) => accumulator + currentValue.amountInEuro, 0)
          )}`}
        </div>
      </div>
      <div style={{ marginBottom: "1.5rem", display: "flex", justifyContent: screen.sm ? "flex-start" : "flex-end" }}>
        <Button type="primary" onClick={() => navigate("/suppliers/add")}>
          Zahlung hinzufügen
        </Button>
      </div>
      <div>
        <SuppliersTable />
      </div>
    </div>
  );
};
