import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginContainer } from "./container/LoginContainer";
import { CustomLayout } from "./components/Layouts/CustomLayout";
import { Dashboard } from "./container/Dashboard";
import { Families } from "./container/Families";
import { Suppliers } from "./container/Suppliers";
import { Guests } from "./container/Guests";
import { SeatingPlan } from "./container/SeatingPlan";
import { Todos } from "./container/Todos";
import { ProvideData } from "./context/Data";
import { RequireAuth } from "./components/RequireAuth";
import { NotFound } from "./components/NotFound";
import { Payments } from "./container/Payments";
import { AddPayment } from "./container/AddPayment";
import { AddSupplier } from "./container/AddSupplier";
import { ProvideMetadata } from "./context/Metadata";

export const App = () => {
  return (
    <BrowserRouter>
      <ProvideMetadata>
        <ProvideData>
          <Routes>
            <Route element={<CustomLayout />}>
              <Route path="/login" element={<LoginContainer />} />
              <Route element={<RequireAuth />}>
                <Route path="/dashboard" element={<Dashboard />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/families" element={<Families />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/guests" element={<Guests />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/suppliers" element={<Suppliers />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/suppliers/add" element={<AddSupplier />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/payments" element={<Payments />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/payments/add" element={<AddPayment />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/seatingplan" element={<SeatingPlan />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/todos" element={<Todos />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </ProvideData>
      </ProvideMetadata>
    </BrowserRouter>
  );
};
