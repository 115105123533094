import { AxiosResponse } from "axios";
import { CreateSupplierDto } from "../../shared/dtos/CreateSupplier.dto";
import { Supplier } from "../../shared/interfaces/Supplier.interface";
import supplierResourceService from "./rest-resources/Supplier-Resource.service";

class SupplierService {
  public async getAll() {
    const response: AxiosResponse<Array<Supplier>> = await supplierResourceService.getAll();
    return response.data;
  }

  public async create(createSupplierDto: CreateSupplierDto) {
    const response: AxiosResponse<Supplier> = await supplierResourceService.create(createSupplierDto);
    return response.data;
  }

  public async delete(supplierId: number) {
    const response: AxiosResponse = await supplierResourceService.delete(supplierId);
    return response;
  }
}

export const supplierService = new SupplierService();
