import { CreateSupplierDto } from "../../../shared/dtos/CreateSupplier.dto";
import { WebApi } from "../WebApi";

export class SupplierResourceService extends WebApi {
  public async getAll() {
    return this.request({
      method: "GET",
      url: "/supplier",
    });
  }

  public async create(data: CreateSupplierDto) {
    return this.request({
      method: "POST",
      url: "/supplier",
      data,
    });
  }

  public async delete(supplierId: number) {
    return this.request({
      method: "DELETE",
      url: `/supplier/${supplierId}`,
    });
  }
}

const supplierResourceService = new SupplierResourceService();
export default supplierResourceService;
