import { WebApi } from "../WebApi";

export class TableResourceService extends WebApi {
  public async getAll() {
    return this.request({
      method: "GET",
      url: "/table",
    });
  }
}

const tableResourceService = new TableResourceService();
export default tableResourceService;
