import { useState } from "react";
import { Menu } from "antd";
import {
  TeamOutlined,
  UserOutlined,
  AuditOutlined,
  AppstoreOutlined,
  GoldOutlined,
  UnorderedListOutlined,
  EuroOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { MenuInfo } from "rc-menu/lib/interface";
import { useNavigate } from "react-router-dom";

export const Navigation = () => {
  const [selectedKey, setSelectedKey] = useState<Array<string>>(["1"]);

  const navigate = useNavigate();

  const onClickMenuItem = (menuInfo: MenuInfo) => {
    navigate(menuInfo.key);
  };

  const items: Array<ItemType> = [
    {
      key: "dashboard",
      icon: <AppstoreOutlined />,
      label: "Dashboard",
      onClick: (menuInfo) => onClickMenuItem(menuInfo),
    },
    {
      key: "families",
      icon: <TeamOutlined />,
      label: "Familien",
      onClick: (menuInfo) => onClickMenuItem(menuInfo),
    },
    {
      key: "guests",
      icon: <UserOutlined />,
      label: "Gäste",
      onClick: (menuInfo) => onClickMenuItem(menuInfo),
    },
    {
      key: "suppliers",
      icon: <GoldOutlined />,
      label: "Dienstleister",
      onClick: (menuInfo) => onClickMenuItem(menuInfo),
    },
    {
      key: "payments",
      icon: <EuroOutlined />,
      label: "Zahlungen",
      onClick: (menuInfo) => onClickMenuItem(menuInfo),
    },
    {
      key: "seatingplan",
      icon: <AuditOutlined />,
      label: "Sitzplan",
      onClick: (menuInfo) => onClickMenuItem(menuInfo),
    },
    {
      key: "todos",
      icon: <UnorderedListOutlined />,
      label: "To Do Liste",
      onClick: (menuInfo) => onClickMenuItem(menuInfo),
    },

  ];

  return (
    <Menu
      theme="dark"
      selectedKeys={selectedKey}
      defaultSelectedKeys={selectedKey}
      items={items}
      onClick={(props) => setSelectedKey([props.key])}
    />
  );
};
