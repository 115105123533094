import { AxiosResponse } from "axios";
import { UpdateFamilyDto } from "../../shared/dtos/UpdateFamily.dto";
import { Family } from "../../shared/interfaces/Family.interface";
import familyResourceService from "./rest-resources/Family-Resource.service";

class FamilyService {
  public async getAll() {
    const response: AxiosResponse<Array<Family>> = await familyResourceService.getAll();
    return response.data;
  }

  public async update(id: number, updateFamilyDto: UpdateFamilyDto) {
    const response: AxiosResponse<Array<Family>> = await familyResourceService.update(id, updateFamilyDto);
    return response.data;
  }
}

export const familyService = new FamilyService();
