import { AxiosResponse } from "axios";
import { CreatePaymentDto } from "../../shared/dtos/CreatePayment.dto";
import { Payment } from "../../shared/interfaces/Payment.interface";
import paymentResourceService from "./rest-resources/Payment-Resource.service";

class PaymentService {
  public async getAll() {
    const response: AxiosResponse<Array<Payment>> = await paymentResourceService.getAll();
    return response.data;
  }

  public async create(createPaymentDto: CreatePaymentDto) {
    const response: AxiosResponse<Payment> = await paymentResourceService.create(createPaymentDto);
    return response.data;
  }

  public async delete(id: number) {
    const response: AxiosResponse = await paymentResourceService.delete(id);
    return response;
  }
}

export const paymentService = new PaymentService();
