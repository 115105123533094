import { notification } from "antd";
import { ContainerHeader } from "../components/ContainerHeader";
import { useData } from "../context/Data";
import { TodoForm, TodoFormValues } from "../components/forms/TodoForm";
import { todoService } from "../services/api/Todo.service";
import { CreateTodoDto } from "../shared/dtos/CreateTodo.dto";
import { useState } from "react";
import { TodoList } from "../components/todos/TodoList";
export const Todos = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setTodos } = useData();
  const [api, contextHolder] = notification.useNotification();

  const onFormSubmit = async (values: TodoFormValues): Promise<void> => {
    const createTodoDto: CreateTodoDto = {
      title: values.title,
      description: values.description,
      responsiblePerson: values.resonsiblePerson,
      dueDate: values.dueDate.toDate(),
    };
    try {
      const response = await todoService.create(createTodoDto);
      setTodos((prevState) => [...prevState, response]);
      api.success({
        message: "Todo erfolgreich hinzugefügt.",
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <ContainerHeader title="Todos" />
      <TodoForm onSubmit={onFormSubmit} isLoading={isLoading} />
      <TodoList />
    </>
  );
};
