import { Button, DatePicker, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import dayjs from "dayjs";

export interface TodoFormValues {
  title: string;
  dueDate: dayjs.Dayjs;
  resonsiblePerson: string;
  description: string;
}

interface TodoFormProps {
  isLoading: boolean;
  onSubmit: (values: TodoFormValues) => void;
}

export const TodoForm: React.FC<TodoFormProps> = ({ onSubmit, isLoading }): React.ReactElement => {
  return (
    <Form style={{ margin: "0 0 1rem 0" }} onFinish={onSubmit} layout="vertical">
      <Form.Item name="title" rules={[{ required: true, message: "Titel ist erforderlich" }]}>
        <Input placeholder="Todo" />
      </Form.Item>
      <div style={{ display: "flex", gap: 5, margin: ".2rem 0" }}>
        <Form.Item name="dueDate" rules={[{ required: true, message: "Fälligkeitsdatum ist erforderlich" }]}>
          <DatePicker showTime placeholder="Fälligkeitsdatum" />
        </Form.Item>
        <Form.Item
          name="resonsiblePerson"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Zuständige Person ist erforderlich" }]}
        >
          <Input placeholder="Zuständige(r)" />
        </Form.Item>
      </div>
      <Form.Item name="description" rules={[{ required: true, message: "Beschreibung ist erforderlich" }]}>
        <TextArea placeholder="Beschreibung" />
      </Form.Item>
      <Button htmlType="submit" type="primary" style={{ width: "100%", margin: ".2rem 0" }} loading={isLoading}>
        Hinzufügen
      </Button>
    </Form>
  );
};
