import { Button, notification, Row } from "antd";
import { useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { ContainerHeader } from "../components/ContainerHeader";
import { BridalCoupleTableDroppable } from "../components/Droppables/BridalCoupleTableDroppable";
import { GuestsWithNoTableDroppable } from "../components/Droppables/GuestsWithNoTableDroppable";
import { GuestTablesDroppables } from "../components/Droppables/GuestTablesDroppables";
import { useData } from "../context/Data";
import { guestService } from "../services/api/Guest.service";
import { tableService } from "../services/api/Table.service";
import { UpdateSeatingPlan } from "../shared/dtos/UpdateSeatingPlan.dto";
import { Guest } from "../shared/interfaces/Guest.interface";
import { ITable } from "../shared/interfaces/Table.interface";

export interface TableDroppable {
  key: number;
  id: number;
  availableSeats: number;
  name: string;
  guests: Array<Guest>;
}

export const SeatingPlan = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tables, setTables] = useState<Array<TableDroppable>>([]);
  const { guests, setGuests } = useData();
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    const mapTablesToDragAndDroppables = (tables: Array<ITable>) => {
      return tables.map((table) => ({
        key: table.id,
        id: table.id,
        availableSeats: table.amountOfSeats,
        name: table.name,
        guests: guests.filter((guest) => guest.table?.id === table.id),
      }));
    };

    const getTables = async () => {
      try {
        const tables = await tableService.getAll();
        const mappedTables = mapTablesToDragAndDroppables(tables);
        const guestsWithNoTable = guests.filter((guest) => guest.table === null);
        mappedTables.push({ id: 0, key: 0, availableSeats: Infinity, guests: guestsWithNoTable, name: "NO TABLE" });
        setTables(mappedTables);
      } catch (error) {
        console.warn(error);
      }
    };
    getTables();
  }, [guests]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const tablesCopy = [...tables];

    const sourceTable = tablesCopy.find((table) => table.id === Number(result.source.droppableId));
    const destinationTable = tablesCopy.find((table) => table.id === Number(result.destination?.droppableId));
    const guest = guests.find((guest) => guest.id === Number(result.draggableId));

    if (sourceTable && guest && destinationTable) {
      destinationTable.guests.push(guest);

      var guestIndex = sourceTable.guests.findIndex((g: Guest) => g.id.toString() === result.draggableId);

      sourceTable.guests.splice(guestIndex, 1);
    }

    setTables(tablesCopy);
  };

  const onClickSave = async (): Promise<void> => {
    try {
      const seatingPlan: Array<UpdateSeatingPlan> = [];
      for (const table of tables) {
        for (const guest of table.guests) {
          const updateItem: UpdateSeatingPlan = {
            guestId: guest.id,
            tableId: table.id,
          };
          seatingPlan.push(updateItem);
        }
      }

      const updatedGuests = await guestService.updateSeatingPlan(seatingPlan);

      const guestsCopy = [...guests];

      for (let guest of guestsCopy) {
        for (const updatedGuest of updatedGuests) {
          if (guest.id === updatedGuest.id) {
            guest.table = updatedGuest.table;
          }
        }
      }
      api.success({
        message: "Sitzplan erfolgreich aktualisiert.",
        placement: "topRight",
      });
      setGuests(guestsCopy);
    } catch (err) {
      api.error({
        message: "Aktualisierung fehlgeschlagen.",
        placement: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row>
      <ContainerHeader title="Sitzplan" />
      {contextHolder}
      <DragDropContext onDragEnd={onDragEnd}>
        <BridalCoupleTableDroppable table={tables.find((table) => table.name === "Brautpaar")} />
        <GuestTablesDroppables
          tables={tables.filter((table) => table.name !== "Brautpaar" && table.name !== "NO TABLE")}
        />
        <GuestsWithNoTableDroppable table={tables.find((table) => table.name === "NO TABLE")} />
      </DragDropContext>
      <Button type="primary" style={{ width: "100%", margin: ".2rem" }} onClick={onClickSave} loading={isLoading}>
        Speichern
      </Button>
    </Row>
  );
};
