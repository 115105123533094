import { AxiosResponse } from "axios";
import { WebApi } from "../WebApi";

export class AuthResourceService extends WebApi {
  constructor() {
    super(true, process.env.REACT_APP_OAUTH_URL);
  }

  public async login(email: string, password: string): Promise<AxiosResponse> {
    return this.request({
      method: "POST",
      url: "/auth/login",
      data: { email, password },
    });
  }

  public async authenticate(): Promise<AxiosResponse> {
    return this.request({
      method: "GET",
      url: "/auth/authenticate",
    });
  }

  public async logout(): Promise<void> {
    this.request({
      method: "POST",
      url: "/auth/logout",
    });
  }
}

const authResourceService = new AuthResourceService();
export default authResourceService;
