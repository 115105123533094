import { AxiosResponse } from "axios";
import { UpdateGuestDto } from "../../shared/dtos/UpdateGuest.dto";
import { UpdateSeatingPlan } from "../../shared/dtos/UpdateSeatingPlan.dto";
import { Guest } from "../../shared/interfaces/Guest.interface";
import guestResourceService from "./rest-resources/Guest-Resource.service";

class GuestService {
  public async getAll() {
    const response: AxiosResponse<Array<Guest>> = await guestResourceService.getAll();
    return response.data;
  }

  public async toggleAttendance(id: number) {
    const response: AxiosResponse<Guest> = await guestResourceService.toggleAttendance(id);
    return response.data;
  }

  public async updateSeatingPlan(seatingPlan: Array<UpdateSeatingPlan>) {
    const response: AxiosResponse<Array<Guest>> = await guestResourceService.updateSeatingPlan(seatingPlan);
    return response.data;
  }

  public async update(updateGuestDto: UpdateGuestDto) {
    const response: AxiosResponse<Guest> = await guestResourceService.update(updateGuestDto);
    return response.data;
  }

  public async delete(id: number) {
    const response: AxiosResponse = await guestResourceService.delete(id);
    return response;
  }
}

export const guestService = new GuestService();
