import { createContext, useContext, useState } from "react";

interface Props {
    isPublicView: boolean;
    setIsPublicView: (isPublicView: boolean) => void
}

const initialContext: Props = {
    isPublicView: true,
    setIsPublicView: () => { }
};

interface ProvideMetadataProps {
    children: React.ReactElement;
}

const metadataContext = createContext(initialContext);

export const ProvideMetadata: React.FC<ProvideMetadataProps> = ({ children }) => {
    const auth = useProvideMetadata();
    return <metadataContext.Provider value={auth}>{children}</metadataContext.Provider>;
};

export const useMetadata = () => {
    return useContext(metadataContext);
};

const useProvideMetadata = () => {
    const [isPublicView, setIsPublicView] = useState<boolean>(true);

    return {
        isPublicView,
        setIsPublicView
    };
};
