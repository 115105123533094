import { UpdateFamilyDto } from "../../../shared/dtos/UpdateFamily.dto";
import { WebApi } from "../WebApi";

export class FamilyResourceService extends WebApi {
  public async getAll() {
    return this.request({
      method: "GET",
      url: "/family",
    });
  }

  public async update(id: number, data: UpdateFamilyDto) {
    return this.request({
      method: "PATCH",
      url: `/family/${id}`,
      data,
    });
  }
}

const familyResourceService = new FamilyResourceService();
export default familyResourceService;
