import { AxiosResponse } from "axios";
import { ITable } from "../../shared/interfaces/Table.interface";
import tableResourceService from "./rest-resources/Table-Resource.service";

class TableService {
  public async getAll() {
    const response: AxiosResponse<Array<ITable>> = await tableResourceService.getAll();
    return response.data;
  }
}

export const tableService = new TableService();
