import { Button, Form, Input, Typography } from "antd";
import React from "react";

interface LoginFormProps {
  onFormSubmit: (values: any) => void;
  isLoading: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onFormSubmit, isLoading }) => {
  const { Title } = Typography;

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Title level={2}>Weddingplanner</Title>
      <Form
        name="login"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFormSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="on"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Bitte E-Mail Adresse eingeben!" }]}
          style={{ margin: ".5rem 0" }}
        >
          <Input type="email" placeholder="E-Mail" />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true, message: "Bitte Passwort eingeben!" }]}>
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
