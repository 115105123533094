import { Button, Form, Grid, Input, InputNumber } from "antd";
import React from "react";

interface SupplierFormProps {
  onSubmit: (values: any) => void;
  isLoading: boolean;
}

export interface SupplierFormValues {
  name: string;
  job: string;
  totalCostsInEuro: number;
}

export const SupplierForm: React.FC<SupplierFormProps> = ({ onSubmit, isLoading }) => {
  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: screen.md ? "50%" : "100%" }}>
          <Form onFinish={onSubmit} layout="vertical">
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "Bitte Namen eingeben!" }]}>
              <Input />
            </Form.Item>
            <Form.Item name="job" label="Job" rules={[{ required: true, message: "Bitte Job eingeben!" }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="totalCostsInEuro"
              label="Kosten"
              rules={[{ required: true, message: "Bitte Kosten eingeben!" }]}
            >
              <InputNumber addonAfter="€" decimalSeparator="," style={{ width: "100%" }} precision={2} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" style={{ width: "100%" }} loading={isLoading}>
                Hinzufügen
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
