import { createContext, useContext, useEffect, useState } from "react";
import { familyService } from "../services/api/Family.service";
import { guestService } from "../services/api/Guest.service";
import { paymentService } from "../services/api/Payment.service";
import { supplierService } from "../services/api/Supplier.service";
import { tableService } from "../services/api/Table.service";
import { todoService } from "../services/api/Todo.service";
import { Family } from "../shared/interfaces/Family.interface";
import { Guest } from "../shared/interfaces/Guest.interface";
import { Payment } from "../shared/interfaces/Payment.interface";
import { Supplier } from "../shared/interfaces/Supplier.interface";
import { ITable } from "../shared/interfaces/Table.interface";
import { Todo } from "../shared/interfaces/Todo.interface";
import { useAuth } from "./Auth";

interface Props {
  guests: Array<Guest>;
  families: Array<Family>;
  tables: Array<ITable>;
  suppliers: Array<Supplier>;
  payments: Array<Payment>;
  todos: Array<Todo>;
  isLoading: boolean;
  setTodos: React.Dispatch<React.SetStateAction<Todo[]>>;
  setGuests: React.Dispatch<React.SetStateAction<Guest[]>>;
  setFamilies: React.Dispatch<React.SetStateAction<Family[]>>;
  setTables: React.Dispatch<React.SetStateAction<ITable[]>>;
  setSuppliers: React.Dispatch<React.SetStateAction<Supplier[]>>;
  setPayments: React.Dispatch<React.SetStateAction<Payment[]>>;
}

const initialContext: Props = {
  guests: [],
  families: [],
  tables: [],
  suppliers: [],
  payments: [],
  todos: [],
  isLoading: false,
  setGuests: () => {},
  setFamilies: () => {},
  setTables: () => {},
  setSuppliers: () => {},
  setTodos: () => {},
  setPayments: () => {},
};

interface ProvideGuestsProps {
  children: React.ReactElement;
}

const guestContext = createContext(initialContext);

export const ProvideData: React.FC<ProvideGuestsProps> = ({ children }) => {
  const guest = useProvideData();
  return <guestContext.Provider value={guest}>{children}</guestContext.Provider>;
};

export const useData = () => {
  return useContext(guestContext);
};

const useProvideData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [families, setFamilies] = useState<Array<Family>>([]);
  const [guests, setGuests] = useState<Array<Guest>>([]);
  const [tables, setTables] = useState<Array<ITable>>([]);
  const [suppliers, setSuppliers] = useState<Array<Supplier>>([]);
  const [payments, setPayments] = useState<Array<Payment>>([]);
  const [todos, setTodos] = useState<Array<Todo>>([]);

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const getGuests = async (): Promise<Array<Guest>> => {
      try {
        return await guestService.getAll();
      } catch (error) {
        console.warn(error);
        throw error;
      }
    };

    const getFamilies = async (): Promise<Array<Family>> => {
      try {
        const families = await familyService.getAll();
        return families;
      } catch (error) {
        console.warn(error);
        throw error;
      }
    };

    const getSuppliers = async (): Promise<Array<Supplier>> => {
      try {
        return await supplierService.getAll();
      } catch (error) {
        console.warn(error);
        throw error;
      }
    };

    const getTodos = async (): Promise<Array<Todo>> => {
      try {
        return await todoService.getAll();
      } catch (error) {
        console.warn(error);
        throw error;
      }
    };

    const getTables = async (): Promise<Array<ITable>> => {
      try {
        return await tableService.getAll();
      } catch (error) {
        console.warn(error);
        throw error;
      }
    };

    const getPayments = async (): Promise<Array<Payment>> => {
      try {
        return await paymentService.getAll();
      } catch (error) {
        console.warn(error);
        throw error;
      }
    };

    let shouldLoadData;

    if (process.env.NODE_ENV === "development") {
      shouldLoadData = true;
    } else {
      shouldLoadData = isAuthenticated;
    }

    if (shouldLoadData) {
      setIsLoading(true);
      Promise.all([getFamilies(), getGuests(), getSuppliers(), getTodos(), getTables(), getPayments()])
        .then((values) => {
          setFamilies(values[0]);
          setGuests(values[1]);
          setSuppliers(values[2]);
          setTodos(values[3]);
          setTables(values[4]);
          setPayments(values[5]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated]);

  return {
    isLoading,
    families,
    guests,
    tables,
    suppliers,
    payments,
    todos,
    setFamilies,
    setGuests,
    setTables,
    setSuppliers,
    setTodos,
    setPayments,
  };
};
