import { Button, List } from "antd";
import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Todo } from "../../shared/interfaces/Todo.interface";

interface TodoListItemProps {
  toggle: (id: number) => void;
  item: Todo;
}

export const TodoListItem: React.FC<TodoListItemProps> = ({ toggle, item }): React.ReactElement => {
  const getBackgroundColor = (item: Todo): string => {
    const dateNow = new Date();
    const oneWeekInMilliseconds = 604800 * 1000;
    const dueDateTimestamp = new Date(item.dueDate).getTime();
    const timestampNow = dateNow.getTime();

    const restTime = dueDateTimestamp - timestampNow;

    if (item.isDone) {
      return "lightgreen";
    }

    if (restTime < oneWeekInMilliseconds) {
      return "#f87171";
    }

    return "white";
  };

  return (
    <div
      style={{
        borderBottom: "1px solid gray",
        margin: "1px 0",
        padding: ".2rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: getBackgroundColor(item),
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ fontWeight: "bold" }}>{item.isDone ? <s>{item.title}</s> : item.title}</div>
        <List.Item style={{ padding: "0" }}>{item.isDone ? <s>{item.description}</s> : item.description}</List.Item>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          {item.isDone ? (
            <s>{new Date(item.dueDate).toLocaleDateString()}</s>
          ) : (
            new Date(item.dueDate).toLocaleDateString()
          )}
        </div>
        <Button
          type={item.isDone ? "primary" : "dashed"}
          style={{ margin: "0 24px" }}
          icon={<CheckOutlined />}
          onClick={() => toggle(item.id)}
        />
      </div>
    </div>
  );
};
