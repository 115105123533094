import React, { useContext, useEffect, useRef, useState } from "react";
import { InputRef, notification } from "antd";
import { Form, Input, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import { Guest } from "../../shared/interfaces/Guest.interface";
import { useData } from "../../context/Data";
import { familyService } from "../../services/api/Family.service";
import { UpdateFamilyDto } from "../../shared/dtos/UpdateFamily.dto";
import GuestsTable from "./GuestsTable";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  id: number;
  name: string;
  guestsAmount: number;
  guests: Array<Guest>;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

interface DataType {
  id: number;
  key: React.Key;
  name: string;
  guestsAmount: number;
  guests: Array<Guest>;
}

const FamiliesTable: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();

  const { families, setFamilies } = useData();

  const dataSource = families.map((family) => ({
    key: family.id,
    id: family.id,
    name: family.name,
    guestsAmount: family.guests.length,
    guests: family.guests,
  }));

  const defaultColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "Anzahl Gäste",
      dataIndex: "guestsAmount",
      key: "name",
    },
  ];

  const handleSave = async (row: DataType) => {
    const familiesCopy = [...families];
    const index = familiesCopy.findIndex((item) => row.id === item.id);
    const item = familiesCopy[index];

    familiesCopy.splice(index, 1, {
      ...item,
      ...row,
    });
    setFamilies(familiesCopy);

    if (item.name === row.name) {
      return;
    }

    const updateFamilyDto: UpdateFamilyDto = {
      id: row.id,
      name: row.name,
    };
    try {
      await familyService.update(row.id, updateFamilyDto);
      api.success({
        message: "Familienname erfolgreich aktualisiert.",
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      {contextHolder}
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        size="middle"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <GuestsTable selectedFamily={record} />,
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      />
    </div>
  );
};

export default FamiliesTable;
