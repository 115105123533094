import React from "react";
import { ContainerHeader } from "../components/ContainerHeader";
import { PaymentsTable } from "../components/tables/PaymentsTable";

export const Payments: React.FC = (): React.ReactElement => {
  return (
    <div>
      <ContainerHeader title="Zahlungen" />
      <PaymentsTable selectedSupplier={undefined} />
    </div>
  );
};
