import { Card, Col, Input } from "antd";
import React, { useState } from "react";
import { Draggable, DraggingStyle, Droppable, NotDraggingStyle } from "react-beautiful-dnd";
import { TableDroppable } from "../../container/SeatingPlan";
import { Guest } from "../../shared/interfaces/Guest.interface";

interface GuestsWithNoTableDroppableProps {
  table: TableDroppable | undefined;
}

export const GuestsWithNoTableDroppable: React.FC<GuestsWithNoTableDroppableProps> = ({ table }) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const filteredItems = table?.guests.filter((guest) =>
    guest.firstName.toLowerCase().startsWith(searchValue.toLowerCase())
  );

  const getGuestWithNoSeatItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none" as any,
    padding: 6,
    border: "1px solid gray",
    borderRadius: "6px",
    margin: ".2rem",
    // change background colour if dragging
    background: "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return table ? (
    <Col span={24}>
      <Droppable droppableId={"0"} direction="horizontal">
        {(provided, snapshot) => (
          <Card
            ref={provided.innerRef}
            title={`Bisher kein Tisch - ${table.guests.length}`}
            style={{ margin: ".2rem" }}
            bodyStyle={{ display: "flex", flexWrap: "wrap" }}
          >
            <Input
              placeholder="Suche.."
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ margin: "0 6px 1rem 0" }}
            />
            {filteredItems?.map((guest: Guest) => (
              <Draggable key={guest.id.toString()} draggableId={guest.id.toString()} index={guest.id}>
                {(provided, snapshot) => (
                  <div>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getGuestWithNoSeatItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {guest.firstName} {guest.lastName}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Card>
        )}
      </Droppable>
    </Col>
  ) : (
    <div />
  );
};
