import { Breakpoint, Button, notification, Table } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../../context/Data";
import { utils } from "../../services/api/utils/Utils";
import { Payment } from "../../shared/interfaces/Payment.interface";
import { Supplier } from "../../shared/interfaces/Supplier.interface";
import { DeleteOutlined } from "@ant-design/icons";
import { paymentService } from "../../services/api/Payment.service";

interface PaymentsTableProps {
  selectedSupplier: Supplier | undefined;
}

export const PaymentsTable: React.FC<PaymentsTableProps> = ({ selectedSupplier }): React.ReactElement => {
  const { payments, suppliers, setPayments, setSuppliers } = useData();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const getDataSource = () => {
    if (selectedSupplier) {
      return mapPaymentsToTableItems(selectedSupplier.payments);
    } else {
      return mapPaymentsToTableItems(payments);
    }
  };

  const onClickDeletePayment = async (payment: Payment): Promise<void> => {
    const paymentsCopy = [...payments];
    const suppliersCopy = [...suppliers];
    const selectedPayment = paymentsCopy.find((p) => p.id === payment.id);

    try {
      const response = await paymentService.delete(payment.id);
      if (response.status === 200) {
        const paymentIndex = paymentsCopy.findIndex((p) => p.id === payment.id);
        paymentsCopy.splice(paymentIndex, 1);
        setPayments(paymentsCopy);

        const selectedSupplier = suppliersCopy.find((supplier) => supplier.id === payment.supplierId);
        if (selectedSupplier) {
          const selectedSupplierPaymentIndex = selectedSupplier.payments.findIndex((p) => p.id === payment.id);
          selectedSupplier?.payments.splice(selectedSupplierPaymentIndex, 1);
          setSuppliers(suppliersCopy);
        }

        api.success({
          message: `${selectedPayment?.billingNo} erfolgreich entfernt.`,
          placement: "topRight",
        });
      }
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    }
  };

  const mapPaymentsToTableItems = (payments: Array<Payment>) => {
    return payments.map((payment) => ({
      key: payment.id,
      id: payment.id,
      supplier: suppliers.find((supplier) => supplier.id === payment.supplierId)?.name,
      billingNo: payment.billingNo,
      amountInEuro: `${payment.amountInEuro} €`,
      paymentDate: new Date(payment.paymentDate).toLocaleDateString(),
      actions: <Button icon={<DeleteOutlined />} onClick={() => onClickDeletePayment(payment)} />,
    }));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      responsive: ["md"] as Array<Breakpoint>,
    },
    {
      title: "Rechnugsnummer",
      dataIndex: "billingNo",
      key: "billingNo",
    },
    {
      title: "Rechnungsbetrag",
      dataIndex: "amountInEuro",
      key: "amountInEuro",
    },
    {
      title: "Datum",
      dataIndex: "paymentDate",
      key: "paymentDate",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const sumUpTotalAmount = (payments: Array<Payment>) => {
    let sum = 0;
    sum = payments.reduce((accumulator, currentValue) => accumulator + currentValue.amountInEuro, sum);
    return <b>{utils.convertNumberToEuroString(sum)}</b>;
  };

  if (!selectedSupplier) {
    columns.splice(1, 0, { title: "Dienstleister", dataIndex: "supplier", key: "supplier" });
  }

  return (
    <>
      {contextHolder}
      <div style={{ marginBottom: selectedSupplier ? ".2rem" : "1.5rem" }}>
        <Button size={selectedSupplier ? "small" : "middle"} type="primary" onClick={() => navigate("/payments/add")}>
          Zahlung hinzufügen
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={getDataSource()}
        scroll={{ x: 500 }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4}>
                {selectedSupplier ? sumUpTotalAmount(selectedSupplier.payments) : sumUpTotalAmount(payments)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        pagination={false}
      />
    </>
  );
};
