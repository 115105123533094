import { CreatePaymentDto } from "../../../shared/dtos/CreatePayment.dto";
import { WebApi } from "../WebApi";

export class PaymentResourceService extends WebApi {
  public async getAll() {
    return this.request({
      method: "GET",
      url: "/payment",
    });
  }

  public async create(data: CreatePaymentDto) {
    return this.request({
      method: "POST",
      url: "/payment",
      data,
    });
  }

  public async delete(id: number) {
    return this.request({
      method: "DELETE",
      url: `/payment/${id}`,
    });
  }
}

const paymentResourceService = new PaymentResourceService();
export default paymentResourceService;
