import { Spin } from "antd";
import { CountCard } from "../components/cards/CountCard";
import { ContainerHeader } from "../components/ContainerHeader";
import { useData } from "../context/Data";

export const Dashboard = () => {
  const { isLoading, guests, suppliers, families } = useData();

  return (
    <Spin size="large" spinning={isLoading}>
      <div>
        <ContainerHeader title="Dashboard" />
        <div style={{ display: "flex", flexWrap: "wrap", gap: ".2rem" }}>
          <CountCard amount={families.length} title="Familien" hrefTitle="Familien" href="/families" />
          <CountCard amount={guests.length} title="Gäste" hrefTitle="Gäste" href="/guests" />
          <CountCard amount={suppliers.length} title="Dienstleister" hrefTitle="Dienstleister" href="/suppliers" />
          <CountCard
            amount={guests.filter((guest) => guest.table).length}
            title="Vergebene Sitzplätze"
            hrefTitle="Sitzplan"
            href="/seatingplan"
          />
          <CountCard
            amount={guests.filter((guest) => guest.isAttending).length}
            title="Zusagen"
            hrefTitle="Gäste"
            href="/guests"
          />
        </div>
      </div>
    </Spin>
  );
};
