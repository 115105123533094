import { notification } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContainerHeader } from "../components/ContainerHeader";
import { PaymentForm, PaymentFormValues } from "../components/forms/PaymentForm";
import { useData } from "../context/Data";
import { paymentService } from "../services/api/Payment.service";
import { CreatePaymentDto } from "../shared/dtos/CreatePayment.dto";

export const AddPayment: React.FC = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { suppliers, setSuppliers, setPayments } = useData();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const onSubmit = async (values: PaymentFormValues) => {
    setIsLoading(true);
    const addPaymentDto = createAddPaymentDto(values);

    try {
      const payment = await paymentService.create(addPaymentDto);
      const suppliersCopy = [...suppliers];
      const supplier = suppliersCopy.find((supplier) => supplier.id === values.supplierId);
      supplier?.payments.push(payment);
      setPayments((prevState) => [...prevState, payment]);
      setSuppliers(suppliersCopy);
      api.success({
        message: "Zahlung erfolgreich hinzugefügt.",
        placement: "topRight",
      });
      navigate("/payments");
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createAddPaymentDto = (values: PaymentFormValues): CreatePaymentDto => {
    return {
      amountInEuro: values.amountInEuro,
      billingNo: values.billingNo,
      paymentDate: values.paymentDate.toJSON(),
      supplierId: values.supplierId,
    };
  };
  return (
    <>
      {contextHolder}
      <ContainerHeader title="Zahlung hinzufügen" />
      <PaymentForm selectedPayment={undefined} onSubmit={onSubmit} isLoading={isLoading} />
    </>
  );
};
