import { Typography } from "antd";
import React from "react";
import { useMetadata } from "../context/Metadata";

interface ContainerHeaderProps {
  title: string;
}

export const ContainerHeader: React.FC<ContainerHeaderProps> = ({ title }): React.ReactElement => {
  const { Title } = Typography;

  const { isPublicView, setIsPublicView } = useMetadata()

  return (
    <Title level={3} style={{ margin: "0 0 1rem 0" }} onClick={() => setIsPublicView(!isPublicView)}>
      {title}
    </Title>
  );
};
