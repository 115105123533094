import { CreateTodoDto } from "../../../shared/dtos/CreateTodo.dto";
import { WebApi } from "../WebApi";

export class TodoResourceService extends WebApi {
  public async getAll() {
    return this.request({
      method: "GET",
      url: "/todo",
    });
  }

  public async create(data: CreateTodoDto) {
    return this.request({
      method: "POST",
      url: "/todo",
      data,
    });
  }

  public async toggle(id: number) {
    return this.request({
      method: "POST",
      url: `/todo/${id}/toggle`,
    });
  }

  public async update(id: number, data: CreateTodoDto) {
    return this.request({
      method: "PUT",
      url: `/todo/${id}`,
      data,
    });
  }

  public async delete(id: number) {
    return this.request({
      method: "DELETE",
      url: `/todo/${id}`,
    });
  }
}

const todoResourceService = new TodoResourceService();
export default todoResourceService;
