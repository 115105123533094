import { createContext, useContext, useState } from "react";
import { authService } from "../services/api/Auth.service";

interface Props {
  userName: string | undefined;
  //   userRoles: Array<string>;
  isLoading: boolean;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  //   logout: () => void;
}

const initialContext: Props = {
  userName: undefined,
  //   userRoles: [],
  //   isError: false,
  isAuthenticated: false,
  isLoading: false,
  login: async (email: string, password: string) => {},
  //   logout: () => {},
};

interface ProvideAuthProps {
  children: React.ReactElement;
}

const authContext = createContext(initialContext);

export const ProvideAuth: React.FC<ProvideAuthProps> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | undefined>(undefined);

  const login = async (email: string, password: string): Promise<void> => {
    setIsLoading(true);
    const response = await authService.login(email, password);
    setUserName(response.username);
    setIsAuthenticated(true);
    setIsLoading(false);
  };

  // const checkAuthentication = async (): Promise<void> => {
  //   try {
  //     const response = await authService.authenticate();
  //     setUserName(response.username);
  //     setIsAuthenticated(true);
  //   } catch (err) {
  //     setIsAuthenticated(false);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   checkAuthentication().catch((err) => console.warn(err));
  // }, []);

  return {
    userName,
    login,
    isLoading,
    isAuthenticated,
  };
};
