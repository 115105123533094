import { notification } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContainerHeader } from "../components/ContainerHeader";
import { SupplierForm, SupplierFormValues } from "../components/forms/SupplierForm";
import { useData } from "../context/Data";
import { supplierService } from "../services/api/Supplier.service";
import { CreateSupplierDto } from "../shared/dtos/CreateSupplier.dto";

export const AddSupplier: React.FC = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const { setSuppliers } = useData();
  const navigate = useNavigate();

  const onSubmit = async (values: SupplierFormValues) => {
    setIsLoading(true);
    const addPaymentDto = createAddPaymentDto(values);

    try {
      const supplier = await supplierService.create(addPaymentDto);
      setSuppliers((prevState) => [...prevState, supplier]);
      api.success({
        message: "Dienstleister erfolgreich hinzugefügt.",
        placement: "topRight",
      });
      navigate("/suppliers");
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createAddPaymentDto = (values: SupplierFormValues): CreateSupplierDto => {
    return {
      name: values.name,
      job: values.job,
      totalCostsInEuro: values.totalCostsInEuro,
    };
  };

  return (
    <>
      {contextHolder}
      <ContainerHeader title="Dienstleister hinzufügen" />
      <SupplierForm isLoading={isLoading} onSubmit={onSubmit} />
    </>
  );
};
