import React from "react";
import { ContainerHeader } from "../components/ContainerHeader";
import FamiliesTable from "../components/tables/FamiliesTable";

export const Families = () => {
  return (
    <div>
      <ContainerHeader title="Familien" />
      <FamiliesTable />
    </div>
  );
};
