import { AxiosResponse } from "axios";
import { CreateTodoDto } from "../../shared/dtos/CreateTodo.dto";
import { Todo } from "../../shared/interfaces/Todo.interface";
import todoResourceService from "./rest-resources/Todo-Resource.service";

class TodoService {
  public async getAll() {
    const response: AxiosResponse<Array<Todo>> = await todoResourceService.getAll();
    return response.data;
  }

  public async create(createTodoDto: CreateTodoDto) {
    const response: AxiosResponse<Todo> = await todoResourceService.create(createTodoDto);
    return response.data;
  }

  public async toggle(id: number) {
    const response: AxiosResponse<Todo> = await todoResourceService.toggle(id);
    return response.data;
  }

  public async update(id: number, todo: CreateTodoDto) {
    const response: AxiosResponse<Todo> = await todoResourceService.update(id, todo);
    return response.data;
  }

  public async delete(id: number) {
    const response: AxiosResponse = await todoResourceService.delete(id);
    return response;
  }
}

export const todoService = new TodoService();
