import { Grid, Layout } from "antd";
import { Outlet } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { Navigation } from "../Navigation";
import { useState } from "react";
import { useAuth } from "../../context/Auth";

export const CustomLayout = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;

  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  const { isAuthenticated } = useAuth();
  let isAuthenticatedAndProdEnv;

  if (process.env.NODE_ENV === "development") {
    isAuthenticatedAndProdEnv = true;
  } else {
    isAuthenticatedAndProdEnv = isAuthenticated;
  }

  return isAuthenticatedAndProdEnv ? (
    <Layout style={{ height: "100%" }}>
      <Sider breakpoint="lg" collapsedWidth="0">
        <div
          className="logo"
          style={{
            height: "32px",
            margin: "24px",
            background: "rgba(255, 255, 255, 0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "35px",
          }}
        >
          {collapsed ? (
            <FormOutlined style={{ color: "white" }} />
          ) : (
            <Title level={5} style={{ margin: 0, color: "white" }}>
              Weddingplanner
            </Title>
          )}
        </div>
        <Navigation />
      </Sider>
      <Layout>
        <Content style={{ margin: breakpoints.sm ? "24px 16px" : "3px", height: "100%", overflow: "auto" }}>
          <div
            style={{ padding: breakpoints.sm ? 24 : 12, background: "#FFFFFF", minHeight: "100%", overflow: "auto" }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  ) : (
    <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Outlet />
    </div>
  );
};
