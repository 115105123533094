import { ContainerHeader } from "../components/ContainerHeader";
import GuestsTable from "../components/tables/GuestsTable";

export const Guests = () => {

  return (
    <div>
      <ContainerHeader title="Gäste" />
      <GuestsTable selectedFamily={undefined} />
    </div>
  );
};
