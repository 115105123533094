import { List, notification } from "antd";
import { useData } from "../../context/Data";
import { todoService } from "../../services/api/Todo.service";
import { TodoListItem } from "./TodoListItem";
import { Todo } from "../../shared/interfaces/Todo.interface";

export const TodoList = () => {
  const { todos, setTodos } = useData();
  const [api, contextHolder] = notification.useNotification();

  const renderTodos = () => {
    const openTodos = todos
      .filter((todo) => !todo.isDone)
      .sort((itemA, itemB) => new Date(itemA.dueDate).getTime() - new Date(itemB.dueDate).getTime())
      .map((todo) => ({
        id: todo.id,
        title: todo.title,
        description: todo.description,
        responsiblePerson: todo.responsiblePerson,
        dueDate: todo.dueDate,
        isDone: todo.isDone,
      }));

    const closedTodos = todos
      .filter((todo) => todo.isDone)
      .sort((itemA, itemB) => new Date(itemB.dueDate).getTime() - new Date(itemA.dueDate).getTime())
      .map((todo) => ({
        id: todo.id,
        title: todo.title,
        description: todo.description,
        responsiblePerson: todo.responsiblePerson,
        dueDate: todo.dueDate,
        isDone: todo.isDone,
      }));

    return [...openTodos, ...closedTodos];
  };

  const toggleTodoItem = async (id: number): Promise<void> => {
    try {
      const response = await todoService.toggle(id);
      const todoCopy = [...todos];
      const selectedTodo = todoCopy.find((todo) => todo.id === id);
      if (selectedTodo) {
        selectedTodo.isDone = response.isDone;
        setTodos(todoCopy);
        api.success({
          message: "Todo erfolgreich bearbeitet",
          placement: "topRight",
        });
      }
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    }
  };

  // const tomorrow = new Date("2023-12-31 09:55:06.741000");
  // const nextWeek = new Date("2024-01-31 09:58:30.495000");
  // const yesterday = new Date("2023-10-15 09:00:17.848000");

  // const source: Array<Todo> = [
  //   {
  //     id: 1,
  //     description: "TOMORROW",
  //     dueDate: tomorrow,
  //     isDone: false,
  //     responsiblePerson: "Adrian",
  //     title: "TOMORROW",
  //   },
  //   {
  //     id: 2,
  //     description: "NEXT WEEK",
  //     dueDate: nextWeek,
  //     isDone: false,
  //     responsiblePerson: "Adrian",
  //     title: "NEXT WEEK",
  //   },
  //   {
  //     id: 2,
  //     description: "YESTERDAY",
  //     dueDate: yesterday,
  //     isDone: false,
  //     responsiblePerson: "Adrian",
  //     title: "YESTERDAY",
  //   },
  // ];

  return (
    <>
      {contextHolder}
      <List
        itemLayout="horizontal"
        dataSource={renderTodos()}
        renderItem={(item) => <TodoListItem item={item} toggle={toggleTodoItem} />}
      />
    </>
  );
};
