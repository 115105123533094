import "dayjs/locale/de";
import * as dayjs from "dayjs";
import { Button, ConfigProvider, DatePicker, Form, Grid, Input, InputNumber, Select } from "antd";
import React from "react";
import { Payment } from "../../shared/interfaces/Payment.interface";

import locale from "antd/es/locale/de_DE";
import { useData } from "../../context/Data";

interface PaymentFormProps {
  selectedPayment: Payment | undefined;
  onSubmit: (values: any) => void;
  isLoading: boolean;
}

export interface PaymentFormValues {
  billingNo: string;
  amountInEuro: number;
  paymentDate: dayjs.Dayjs;
  supplierId: number;
}

export const PaymentForm: React.FC<PaymentFormProps> = ({ selectedPayment, onSubmit, isLoading }) => {
  const { suppliers } = useData();
  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: screen.md ? "50%" : "100%" }}>
          <ConfigProvider locale={locale}>
            <Form onFinish={onSubmit} layout="vertical">
              <Form.Item
                name="billingNo"
                label="Rechnungsnummer"
                rules={[{ required: true, message: "Bitte Rechnungsnummer eingeben!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="amountInEuro"
                label="Rechnungsbetrag"
                rules={[{ required: true, message: "Bitte Rechnungsbetrag eingeben!" }]}
              >
                <InputNumber addonAfter="€" decimalSeparator="," style={{ width: "100%" }} precision={2} />
              </Form.Item>
              <Form.Item
                name="paymentDate"
                label="Tag der Zahlung"
                rules={[{ required: true, message: "Bitte Datum der Zahlung angeben!" }]}
              >
                <DatePicker showTime={false} placeholder="Datum auswählen" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="supplierId"
                label="Dienstleister"
                rules={[{ required: true, message: "Bitte Dienstleister auswählen!" }]}
              >
                <Select
                  options={suppliers.map((supplier) => ({ label: supplier.name, value: supplier.id }))}
                  placeholder="Dienstleister auswählen"
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary" style={{ width: "100%" }} loading={isLoading}>
                  Hinzufügen
                </Button>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>
      </div>
    </>
  );
};
