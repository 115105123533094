import { notification } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "../components/forms/LoginForm";
import { useAuth } from "../context/Auth";

export const LoginContainer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const onFormSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      await auth.login(values.email, values.password);
      navigate("/dashboard");
    } catch (err) {
      api.error({
        message: "Wrong username or password",
        placement: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <LoginForm onFormSubmit={onFormSubmit} isLoading={isLoading} />
    </div>
  );
};
