import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, InputRef, notification, Switch, Tag } from "antd";
import { Form, Input, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import { Guest } from "../../shared/interfaces/Guest.interface";
import { useData } from "../../context/Data";
import { familyService } from "../../services/api/Family.service";
import { UpdateFamilyDto } from "../../shared/dtos/UpdateFamily.dto";
import { Family } from "../../shared/interfaces/Family.interface";
import { guestService } from "../../services/api/Guest.service";
import { UpdateGuestDto } from "../../shared/dtos/UpdateGuest.dto";
import { utils } from "../../services/api/utils/Utils";
import { DeleteOutlined } from "@ant-design/icons";
import { useMetadata } from "../../context/Metadata";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface TableItem {
  id: number;
  key: React.Key;
  firstName: string;
  lastName: string;
  attending: JSX.Element;
  possibleGiftValue: number;
  giftValue: number;
  table: JSX.Element | undefined;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof TableItem;
  record: TableItem;
  handleSave: (record: TableItem) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

interface DataType {
  id: number;
  key: React.Key;
  firstName: string;
  lastName: string;
  attending: JSX.Element;
  possibleGiftValue: number;
  giftValue: number;
  table: JSX.Element | undefined;
  actions: JSX.Element;
}

interface GuestTableProps {
  selectedFamily: Family | undefined;
}

const GuestsTable: React.FC<GuestTableProps> = ({ selectedFamily }): React.ReactElement => {
  const [api, contextHolder] = notification.useNotification();
  const { guests, setGuests, families, setFamilies } = useData();
  const [tableColumns, setTableColumns] = useState<Array<any>>([])

  const { isPublicView } = useMetadata()

  const toggleAttendance = async (id: number): Promise<void> => {
    const updatedGuest = await guestService.toggleAttendance(id);

    if (selectedFamily) {
      const familiesCopy = [...families];

      const selectedGuest = selectedFamily.guests.find((guest) => guest.id === id);
      if (selectedGuest) {
        selectedGuest.isAttending = updatedGuest.isAttending;
      }

      for (let family of familiesCopy) {
        if (family.id === selectedFamily.id) {
          family = selectedFamily;
        }
      }

      setFamilies(familiesCopy);
    }

    const guestCopy = [...guests];
    const selectedGuest = guestCopy.find((guest) => guest.id === updatedGuest.id);

    if (selectedGuest) {
      selectedGuest.isAttending = updatedGuest.isAttending;
    }

    setGuests(guestCopy);
  };



  const getDataSource = () => {
    if (!selectedFamily) {
      return mapGuestsToTableItems(guests);
    } else {
      return mapGuestsToTableItems(selectedFamily.guests);
    }
  };

  const onClickDeleteGuest = async (id: number): Promise<void> => {
    let guestCopy = [...guests];
    let familiesCopy = [...families];
    const selectedGuest = guestCopy.find((guest) => guest.id === id);
    try {
      const response = await guestService.delete(id);
      if (response.status === 200) {
        var guestIndex = guestCopy.findIndex((guest) => guest.id === id);
        guestCopy.splice(guestIndex, 1);
        setGuests(guestCopy);

        if (selectedFamily) {
          let selectedFamilyGuestIndex = selectedFamily?.guests.findIndex((guest) => guest.id === id);
          const index = familiesCopy.findIndex((family) => family.id === selectedFamily.id);
          familiesCopy[index].guests.splice(selectedFamilyGuestIndex, 1);
          setFamilies(familiesCopy);
        }

        api.success({
          message: `${selectedGuest?.firstName} erfolgreich entfernt.`,
          placement: "topRight",
        });
      }
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    }
  };

  const mapGuestsToTableItems = (guests: Array<Guest>) => {
    return guests.map((guest) => ({
      key: guest.id,
      id: guest.id,
      firstName: guest.firstName,
      lastName: guest.lastName,
      attending: <Switch checked={guest.isAttending} onClick={() => toggleAttendance(guest.id)} />,
      possibleGiftValue: guest.possibleGiftValue,
      giftValue: guest.giftValue,
      table: guest.table?.name ? <Tag color={guest.table.color}>{guest.table.name}</Tag> : undefined,
      actions: <Button icon={<DeleteOutlined />} onClick={() => onClickDeleteGuest(guest.id)} />,
    }));
  };

  useEffect(() => {

    const getUniqueLastNameFilter = () => {
      const filterItems: Array<{ key: number; text: string; value: string }> = [];

      for (const guest of guests) {
        const existingItem = filterItems.find((item) => guest.lastName === item.value);

        if (!existingItem) {
          filterItems.push({ key: guest.id, text: guest.lastName, value: guest.lastName });
        }
      }

      return filterItems;
    };

    const publicColumns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 50,
      },
      {
        title: "Vorname",
        dataIndex: "firstName",
        key: "firstName",
        editable: true,
      },
      {
        title: "Nachname",
        dataIndex: "lastName",
        key: "lastName",
        editable: true,
        filters: getUniqueLastNameFilter(),
        filterSearch: true,
        onFilter: (value: string | number | boolean, record: any) => record.lastName.startsWith(value),
        filterMode: "tree" as const,
      },
      {
        title: "Teilnahme",
        dataIndex: "attending",
        key: "attending",
      },
      {
        title: "Tisch",
        dataIndex: "table",
        key: "table",
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
      },

    ];


    const privateTableColumns = [{
      title: "Möglicher Geschenkwert",
      dataIndex: "possibleGiftValue",
      key: "possibleGiftValue",
      editable: true,
    },
    {
      title: "Geschenkwert",
      dataIndex: "giftValue",
      key: "giftValue",
      editable: true,
    },]

    if (isPublicView) {
      setTableColumns(publicColumns)
    } else {
      setTableColumns([...publicColumns, ...privateTableColumns])
    }

  }, [isPublicView, guests])




  const handleSave = async (row: TableItem) => {
    const guestsCopy = [...guests];
    const index = guestsCopy.findIndex((item) => row.id === item.id);
    const item = guestsCopy[index];

    if (
      item.firstName === row.firstName &&
      item.lastName === row.lastName &&
      item.giftValue === row.giftValue &&
      item.possibleGiftValue === row.possibleGiftValue
    ) {
      return;
    }

    if (selectedFamily) {
      const familiesCopy = [...families];
      const index = familiesCopy.findIndex((item) => row.id === item.id);
      const item = familiesCopy[index];

      familiesCopy.splice(index, 1, {
        ...item,
        ...row,
      });
      setFamilies(familiesCopy);

      const updateFamilyDto: UpdateFamilyDto = {
        id: item.id,
        name: item.name,
      };

      try {
        await familyService.update(row.id, updateFamilyDto);
        api.success({
          message: "Gast erfolgreich aktualisiert.",
          placement: "topRight",
        });
      } catch (error) {
        api.error({
          message: "Etwas ist schief gelaufen.",
          placement: "topRight",
        });
      }
    }

    guestsCopy.splice(index, 1, {
      ...item,
      firstName: row.firstName,
      lastName: row.lastName,
      possibleGiftValue: row.possibleGiftValue,
      giftValue: row.giftValue,
    });
    setGuests(guestsCopy);

    const updateGuestDto: UpdateGuestDto = {
      id: row.id,
      isAttending: item.isAttending,
      firstName: row.firstName,
      lastName: row.lastName,
      possibleGiftValue: row.possibleGiftValue,
      giftValue: row.giftValue,
    };

    try {
      await guestService.update(updateGuestDto);
      api.success({
        message: "Gast erfolgreich aktualisiert.",
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: "Etwas ist schief gelaufen.",
        placement: "topRight",
      });
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const sumUpPossibleGiftValue = (guests: Array<Guest>): string => {
    return utils.convertNumberToEuroString(
      guests.reduce((accumulator, currentValue) => accumulator + Number(currentValue.possibleGiftValue), 0)
    );
  };

  const sumUpGiftValue = (guests: Array<Guest>): string => {
    return utils.convertNumberToEuroString(
      guests.reduce((accumulator, currentValue) => accumulator + Number(currentValue.giftValue), 0)
    );
  };

  const getAmountOfAttendees = (guests: Array<Guest>): string => {
    return `${guests.filter((guest) => guest.isAttending).length} / ${guests.length}`;
  };

  return (
    <div>
      {contextHolder}
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        dataSource={getDataSource()}
        columns={columns}
        pagination={false}
        scroll={{ x: 500 }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4}>
                <b>{selectedFamily ? getAmountOfAttendees(selectedFamily.guests) : getAmountOfAttendees(guests)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} />
              <Table.Summary.Cell index={6} />
              {!isPublicView &&
                <>
                  <Table.Summary.Cell index={7}>
                    <b>{selectedFamily ? sumUpPossibleGiftValue(selectedFamily.guests) : sumUpPossibleGiftValue(guests)}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <b>{selectedFamily ? sumUpGiftValue(selectedFamily.guests) : sumUpGiftValue(guests)}</b>
                  </Table.Summary.Cell>
                </>
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );
};

export default GuestsTable;
